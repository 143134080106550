<template>
  <div class="interview" ref="interview">
    <!--  -->
    <van-nav-bar
      :title="$t('reqrcode.qrTxt1')"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
      :border="false"
    />
    <div class="fixedH" style="height: 1.22667rem;"></div>
    <div class="content">
      <div ref="QRcodeSrcImg" class="QRcodeSrcImg">
        <div class="ewm">
          <qrcode :text="recommendUrl" />
          <!-- <p class="p">{{ $t("home.text7") }}</p> -->
          <p class="p">
            <!-- 长按二维码识别进行简历投递 -->
          </p>
        </div>
        <van-cell-group :border="false">
          <van-cell :title="$t('reqrcode.qrTxt3')" :value="userInfo.empcode" />
          <van-cell :title="$t('reqrcode.qrTxt4')" :value="userInfo.empname" />
        </van-cell-group>
      </div>
      <div class="foot" v-if="showBtn">
        <van-button class="btn" color="#2b8df0" plain @click="onClickRight">{{
          $t('reqrcode.qrTxt2')
        }}</van-button>
      </div>
    </div>
    <!-- 此处是储存截取后的图片 -->
    <div class="photoShow" v-if="photoShow">
      <div class="photo">
        <div class="close">
          <!-- 长按图片保存 -->
          <!-- 请长按二维码进行转发 -->
          <span></span>
          <van-icon name="cross" @click="closePic" />
        </div>
        <img :src="photoUrl" id="img" alt />
        <!-- <span>长按图片保存和分享</span> -->
        <div class="tip">{{ $t('reqrcode.qrTxt6') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import qrcode from '@components/qrcode'
import html2canvas from 'html2canvas'
import { getScanEntryUrl } from '@api/wx.js'
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
export default {
  components: {
    qrcode
  },
  data() {
    return {
      userInfo,
      recommendUrl: '',
      photoShow: false,
      photoUrl: '',
      showBtn: true
    }
  },
  created() {
    // this.recommendUrl =
    //   (window.VUE_APP_DOMAIN
    //     ? window.VUE_APP_DOMAIN
    //     : 'https://v8.hyclound.cn:8090') +
    //   '/scanEntry?module=304&restype=内推&tj_empid=' +
    //   this.userInfo.empid

    getScanEntryUrl().then(res => {
      if (this.$getObject(res) == 'String') {
        this.recommendUrl =
          res +
          '/scanEntry?module=304&restype=内推&tj_empid=' +
          this.userInfo.empid
      }
    })

    // this.recommendUrl =
    //   (window.VUE_APP_DOMAIN
    //     ? window.VUE_APP_DOMAIN
    //     : 'https://v8.hyclound.cn:8090') +
    //   '/#/scanEntry?module=304&restype=内推&tj_empid=' +
    //   this.userInfo.empid
  },
  methods: {
    onClickLeft() {
      this.$router.push('/home')
    },
    onClickRight() {
      let _this = this
      if (_this.photoShow == true) {
        return
      }
      _this.showBtn = false
      // console.log(this.$refs['interview'], 'weqeqeqwewqeqwewq');
      // this.$refs['interview'].getElementsByClassName('van-nav-bar__right')[0].style.display = 'none'
      // 声明一个画布元素,存储需下载图片范围
      // let canvas = document.createElement('canvas')
      // 获取需下载范围元素
      let img = this.$refs['interview']
      // // 图片高度
      // var w = parseInt(window.getComputedStyle(img).width)
      // // alert(w)
      // // alert(window.innerWidth)
      // // 图片宽度
      // var h = parseInt(window.getComputedStyle(img).height)
      // // 声明画布宽高
      // canvas.width = w
      // canvas.height = h
      // canvas.style.width = w + 'px'
      // canvas.style.height = h + 'px'
      // console.log(w, h);
      // let context = canvas.getContext("2d");
      // context.scale(2, 2);
      // 利用 html2canvas 下载 canvas
      setTimeout(() => {
        html2canvas(
          img,
          //  { canvas: canvas }
          {
            width:
              window.innerWidth ||
              document.documentElement.clientWidth ||
              document.body.clientWidth,
            height:
              window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight
          }
        ).then(function(canvas) {
          // _this.$refs['interview'].getElementsByClassName('van-nav-bar__right')[0].style.display = 'flex'
          _this.photoUrl = canvas.toDataURL()
          _this.photoShow = true
          _this.showBtn = true
        })
      }, 100)
    },
    closePic() {
      this.photoShow = false
      this.showBtn = true
      this.photoUrl = ''
    }
  }
}
</script>

<style lang="less" scoped>
.interview {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    .van-hairline--bottom::after {
      border-bottom-width: 0;
    }
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/.van-nav-bar__text {
      color: #fff;
      font-size: 24px;
      font-family: Source Han Sans CN;
    }
  }
  .van-dialog {
    /deep/.van-dialog__content {
      position: relative;
      height: 100%;
      text-align: center;
      .ewm {
        width: 4.52rem;
        margin: 0 auto;
        padding-top: 1rem;
        min-height: 5.52rem;

        img {
          width: 4.52rem;
          height: 4.52rem;
        }
      }
      p {
        width: 100%;
        margin-top: 0.5rem;
      }
      .van-icon {
        width: 28px;
        height: 28px;
        position: absolute;
        right: 40px;
        top: 32px;
      }
    }
  }
  .content {
    height: calc(100% - 92px);
    overflow: auto;
    background: #2b8df0;
    .QRcodeSrcImg {
      background: #2b8df0;
      padding: 30px 0;
      .ewm {
        background: #fff;
        margin: 0 auto;
        width: 80%;
        border-radius: 15px;
        padding: 30px 0 1px;
        img {
          width: 4.52rem;
          height: 4.52rem;
        }
        .p {
          font-size: 36px;
          width: 100%;
          margin-top: 0.5rem;
          text-align: center;
        }
      }
      .van-cell-group {
        margin: 30px auto 0;
        background: #fff;
        width: 80%;
        border-radius: 15px;
        padding: 20px;
      }
    }

    .foot {
      text-align: center;
      margin: 30px auto 0;
      width: 80%;
      .btn {
        border-radius: 15px;
        width: 100%;
        .van-button__content {
          .van-button__text {
            font-size: 36px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .photoShow {
    background: rgba(0, 0, 0, 0.616);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .photo {
      //保存图片容器大小
      width: 80%;
      color: #fff;
      img {
        display: block;
        width: 100%;
        border-radius: 15px;
      }
      .close {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 36px;
      }
      .tip {
        text-align: center;
        color: #ffe91e;
        font-size: 48px;
        position: absolute;
        bottom: 30%;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
      }
    }
  }
}
</style>
